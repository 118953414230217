<template>
  <div class="d-flex flex-column pa-4 mt-2">
    <v-text-field v-model="currentpassword" outlined dense :type="isvisible.currentpass ? 'text' : 'password'"
      label="Current password" placeholder="············"
      :append-icon="isvisible.currentpass ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" clearable
      @click:append="isvisible.currentpass = !isvisible.currentpass" :hide-details="currentpasswordErrors.isvalid"
      :error-messages="currentpasswordErrors.value" @input="$v.currentpassword.$touch()"
      @blur="$v.currentpassword.$touch()" class="mb-2">
    </v-text-field>
    <v-text-field v-model="newpassword" outlined dense :type="isvisible.newpass ? 'text' : 'password'"
      label="New Password" placeholder="············"
      :append-icon="isvisible.newpass ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
      @click:append="isvisible.newpass = !isvisible.newpass" :hide-details="newpasswordErrors.isvalid"
      :error-messages="newpasswordErrors.value" @input="$v.newpassword.$touch()" @blur="$v.newpassword.$touch()"
      clearable class="mb-2">
    </v-text-field>
    <v-text-field v-model="confirmpassword" outlined dense :type="isvisible.confirmpass ? 'text' : 'password'"
      label="Confirm password" placeholder="············"
      :append-icon="isvisible.confirmpass ? icons.mdiEyeOffOutline : icons.mdiEyeOutline" clearable
      @click:append="isvisible.confirmpass = !isvisible.confirmpass" :hide-details="confirmPassErrors.isvalid"
      :error-messages="confirmPassErrors.value">
    </v-text-field>
    <div class="d-flex flex-column pa-4" v-if="errors">
      <span class="error--text" v-for="(err, i) in errors" :key="i">{{ err }}</span>
    </div>
    <v-col col="12" class="">
      <v-btn outlined color="error" class="mr-4" type="reset" @click.prevent="resetValidation">
        Reset
      </v-btn>
      <v-btn color="primary" :loading="loading" @click.prevent="changepassword">
        Save
      </v-btn>
    </v-col>
  </div>
</template>

<script>
import { Drequest } from '@/plugins/Drequest'
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'

export default {
  mixins: [validationMixin],

  validations: {
    newpassword: { required, minLength: minLength(6), maxLength: maxLength(15) },
    currentpassword: { required },
  },
  data() {
    return {
      loading: false,
      isvisible: {
        currentpass: false,
        newpass: false,
        confirmpass: false
      },
      errors: {},
      currentpassword: '',
      newpassword: '',
      confirmpassword: ''
    }
  },
  computed: {
    currentpasswordErrors() {
      const errors = { isvalid: true, value: [] }
      if (!this.$v.currentpassword.$dirty) return errors
      !this.$v.currentpassword.required && errors.value.push('Password is required') && (errors.isvalid = false)
      return errors
    },

    newpasswordErrors() {
      const errors = { isvalid: true, value: [] }
      if (!this.$v.newpassword.$dirty) return errors
      !this.$v.newpassword.minLength && errors.value.push('Password must be at most 6 characters long') && (errors.isvalid = false)
      !this.$v.newpassword.required && errors.value.push('Field is required') && (errors.isvalid = false)
      return errors
    },
    confirmPassErrors() {
      const errors = { isvalid: true, value: [] }
      if (this.newpassword == this.confirmpassword) {
        errors.isvalid = true
      } else {
        errors.value.push('Password must be the same')
        errors.isvalid = false
      }
      return errors
    },
  },

  mounted() {
    this.resetValidation()
  },
  methods: {
    checkValidationForm() {
      if (this.currentpasswordErrors.isvalid && this.newpasswordErrors.isvalid && this.confirmPassErrors.isvalid) {
        return true
      }
      return false
    },
    resetValidation() {
      this.$v.$reset()
      this.currentpassword = ''
      this.newpassword = ''
      this.confirmpassword = ''
    },
    changepassword() {
      this.$v.$touch()
      var formreset = new FormData();
      formreset.append("currentpassword", this.currentpassword);
      formreset.append("newpassword", this.newpassword);
      formreset.append("confirmpassword", this.confirmpassword);
      if (this.checkValidationForm()) {
        this.loading = true;
        Drequest.api(`user.changepassword?user_id=${this.$store.getters.getUser.id}`)
          .data(formreset)
          .post((response) => {
            if (response.success === true) {
              this.loading = false;

              this.$fire({
                type: "succes",
                text: response.detail,
              })
              this.resetValidation()
            }
            else {
              this.loading = false;
              this.$fire({
                type: "error",
                text: response.detail,
              })
            }
          })
          .catch((err) => {
            this.loading = false;
          })
      }
    },

  },
  setup() {
    const icons = {
      mdiEyeOutline,
      mdiEyeOffOutline,
    }
    return {
      icons
    }
  },
}
</script>

<style lang="scss">
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

//@override
.v-card {
  border-radius: 1em !important;
}

h1 {
  margin: 0 !important;
}

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}
</style>
